<template>
  <div class="modal-overlay" v-if="showModal">
    <div class="modal-content">        
      <div style="display: flex; align-items: center; justify-content: space-between; width: 100%; max-width: 100%;">
        <h1 style="margin: 0; font-size: 22px; font-weight: bold;">Edição dos imóveis amostrais vinculados</h1>
        <button type="button" class="plus-icon" @click="openModalAddImoveisManual" :disabled="isMaxImoveisReached">Adicionar Anúncio Manual</button>
      </div>
      <table class="styled-table">
        <thead>
          <tr>
            <th>Endereço</th>
            <th>Área (m²)</th>
            <th style="min-width: 20px">Quartos</th>
            <th style="min-width: 20px">Banheiros</th>
            <th style="min-width: 20px">Vagas</th>
            <th style="min-width: 90px">Preço</th>
            <th style="min-width: 80px">Preço por m²</th>
            <th style="min-width: 60px">Link</th>
            <th style="min-width: 80px">ação</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in formData" :key="item.id" :style="item.adicao_manual ? { backgroundColor: '#F9F3A7' } : {}">
            <td>{{ item.endereco }}</td>
            <td>{{ item.area_m2 }}</td>
            <td>{{ item.total_dormitorios }}</td>
            <td>{{ item.total_bwc }}</td>
            <td>{{ item.vagas_garagem }}</td>
            <td>
              R$
              {{
                item.valor.toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}
            </td>
            <td>
              R$
              {{
                item.area_m2 && item.valor
                  ? (item.valor / item.area_m2).toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : ""
              }}
            </td>
            <td>
              <a
                :href="item.link_anuncio"
                target="_blank"
                rel="noopener noreferrer"
              >
                Abrir link
              </a>
            </td>
            <td>
              <i
                class="icon delet-icon"
                @click="confirmDelete(item.id)"
                title="Deletar"
              ></i>
                <i
                  class="icon baixar-icon"
                  :class="{ 'disabled-icon': !item.pdf_anuncio }"
                  title="Salvar HTML"
                  @click="item.pdf_anuncio ? previewHtml(item.id) : null"
                ></i>

            </td>
          </tr>
        </tbody>
      </table>
      <div class="button-container">
        <div class="avatar-container">
          <div class="avatar-icon"></div>
          <p>Imóvel adicionado manualmente</p>
        </div>

        <button
          type="button"
          class="cancel-button"
          @click="closeModal"
          :disabled="loading"
        >
          Cancelar
        </button>
        <button
          type="button"
          class="submit-button"
          @click="confirmDeleteAll"
          :disabled="loading"
        >
          Deletar todos
        </button>
      </div>
    </div>
    <modal-add-imoveis-manual v-if="showModalAddImoveisManual" :reportId="reportId"  @close="closeModalAddImoveisManual"  @imovelAdicionado="loadReportData(reportId)"/>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import "/src/views/consultaView/components/styleModal.css";
import axios from "axios";
import ModalAddImoveisManual from './ModalAddImoveisManual.vue';
export default {
  components: { ModalAddImoveisManual },
  props: ["reportId"],
  data() {
    return {
      showModal: false,
      showModalAddImoveisManual: false,
      loading: false,
      successMessage: "",
      errorMessage: "",
      formData: [],
    };
  },
  watch: {
    reportId: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.loadReportData(newVal);
          this.showModal = true;
        }
      },
    },
  },
  computed: {
    isMaxImoveisReached() {
      return this.formData.length >= 10;
    }
  },
  methods: {
    async loadReportData(reportId) {
      this.loading = true;
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/api/imoveis/laudo/${reportId}`,
          {
            headers: {
              "x-access-token": process.env.VUE_APP_API_TOKEN,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Erro ao buscar os dados do imóvel");
        }
        const data = await response.json();
        console.log("Dados retornados:", data);
        this.formData = data;
      } catch (error) {
        console.error("Erro ao carregar os dados do imóvel:", error);
        this.errorMessage = "Erro ao carregar os dados do imóvel";
      } finally {
        this.loading = false;
      }
    },
    closeModal() {
      this.showModal = false;
      window.location.reload();
    },
    openModalAddImoveisManual() {
      this.showModalAddImoveisManual = true; // Abre o segundo modal
    },
    closeModalAddImoveisManual() {
      this.showModalAddImoveisManual = false; // Fecha o segundo modal
    },
    async confirmDeleteAll() {
      const result = await Swal.fire({
          title: "Atenção",
          text: "Tem certeza que deseja deletar este imóvel amostral? Essa ação não pode ser desfeita.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Deletar Todos",
          didOpen: () => {
            const cancelButton = Swal.getCancelButton();
            const confirmButton = Swal.getConfirmButton();

            if (cancelButton) {
              cancelButton.style.cssText = `
                background-color: #F4F4F5 !important; 
                color: #18181B !important; 
                border: none !important; 
                border-radius: 4px !important;  
                padding: 10px 20px !important;  
                font-size: 16px !important; 
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
                width: 190px !important; 
                height: 46px !important;  
              `;
            }
            if (confirmButton) {
              confirmButton.style.cssText = `
                background-color: #FF9364 !important; 
                color: #FAFAFA !important; 
                border: none !important; 
                border-radius: 4px !important;  
                padding: 10px 20px !important;  
                font-size: 16px !important; 
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
                width: 190px !important; 
                height: 46px !important;  
              `;
            }
          },
        });

        if (result.isConfirmed) {
          await this.deleteAllReports();
        }
      },
    async confirmDelete(reportId) {
      const result = await Swal.fire({
        title: "Atenção",
        text: "Tem certeza que deseja deletar este imóvel amostral? Essa ação não pode ser desfeita.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Deletar",
        didOpen: () => {
          const cancelButton = Swal.getCancelButton();
          const confirmButton = Swal.getConfirmButton();

          if (cancelButton) {
            cancelButton.style.cssText = `
              background-color: #F4F4F5 !important; 
              color: #18181B !important; 
              border: none !important; 
              border-radius: 4px !important;  
              padding: 10px 20px !important;  
              font-size: 16px !important; 
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
              width: 190px !important; 
              height: 46px !important;  
            `;
          }
          if (confirmButton) {
            confirmButton.style.cssText = `
              background-color: #FF9364 !important; 
              color: #FAFAFA !important; 
              border: none !important; 
              border-radius: 4px !important;  
              padding: 10px 20px !important;  
              font-size: 16px !important; 
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
              width: 190px !important; 
              height: 46px !important;  
            `;
          }
        },
      });

      if (result.isConfirmed) {
        await this.deleteID(reportId);
        Swal.close();
      }
    },
    async deleteAllReports() {
      console.log("Report ID recebido:", this.reportId); // Log do reportId
      this.loading = true;
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/api/imoveis/laudo/${this.reportId}`,
          {
            method: "DELETE",
            headers: {
              "x-access-token": process.env.VUE_APP_API_TOKEN,
            },
          }
        );

        console.log("Resposta da API:", response.status, await response.text()); // Log da resposta
        if (!response.ok) {
          // Caso a API retorne um erro
          const errorMessage = `Erro ao deletar: ${response.status}`;
          console.error(errorMessage);
          throw new Error(errorMessage);
        }

        this.formData = [];
        this.successMessage = "Todos os imóveis foram deletados com sucesso";
        await Swal.fire({
          title: "Sucesso",
          text: this.successMessage,
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
        this.closeModal();
      } catch (error) {
        console.error("Erro ao deletar todos os imóveis:", error);
        this.errorMessage = "Erro ao deletar todos os imóveis";
        await Swal.fire({
          title: "Erro",
          text: this.errorMessage,
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } finally {
        this.loading = false;
      }
    },
    async deleteID(reportId) {
      this.loading = true;
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/api/imoveis/${reportId}`,

          {
            method: "DELETE",
            headers: {
              "x-access-token": process.env.VUE_APP_API_TOKEN,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Erro ao deletar imóveis");
        }
        this.formData = this.formData.filter((item) => item.id !== reportId);
        this.successMessage = "O imóvel foi deletado com sucesso";

        await Swal.fire({
          title: "Sucesso",
          text: this.successMessage,
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } catch (error) {
        console.error("Erro ao deletar imóveis:", error);
        this.errorMessage = "Erro ao deletar imóvel";

        await Swal.fire({
          title: "Erro",
          text: this.errorMessage,
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } finally {
        this.loading = false;
      }
    },
    async previewHtml(id) {
      try {
        console.log("Buscando PDF para o ID:", id);
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/api/imoveis/pdf/${id}`,
          {
            headers: {
              "x-access-token": process.env.VUE_APP_API_TOKEN,
            },
          }
        );

        console.log("Resposta da API:", response.data);

        const pdfUrl = response.data;
        console.log("URL do PDF:", pdfUrl);

        if (pdfUrl) {
          const newWindow = window.open(pdfUrl, "_blank");

          if (!newWindow) {
            Swal.fire({
              icon: "warning",
              title: "Atenção",
              text: "Por favor, permita pop-ups no navegador para visualizar o PDF.",
            });
          } else {
            console.log("Janela de PDF aberta com sucesso.");
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Erro",
            text: "PDF não encontrado para este imóvel.",
          });
        }
      } catch (error) {
        console.error("Erro ao obter o PDF:", error);
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Ocorreu um erro ao tentar obter o arquivo. Por favor, tente novamente.",
        });
      }
    },
  },
};
</script>
