<template>
  <div class="modal-overlay" v-if="showModal" >
    <div class="modal-contents" style="overflow-y: auto; max-height: 90vh; padding: 16px;">
      <i class="pi pi-times close-icon" @click="closeModal" title="Fechar"></i>
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      <h1>Resumo da avaliação</h1>
      <form class="form-1">
        <div class="form-group type-group">
          <div class="form-item">
            <label for="nome_requerente">Nome do requerente</label>
            <p id="nome_requerente">{{ form.nome_comprador }}</p>
          </div>
          <div class="form-item">
            <label for="cpf">CPF do requerente</label>
            <p id="cpf">{{ form.cpf_comprador }}</p>
          </div>
          <div class="form-item">
            <label for="endereco">Endereço</label>
            <p id="endereco">{{ form.endereco }}</p>
          </div>
          <div class="form-item">
            <label for="complemento">Complemento</label>
            <p id="complemento">{{ form.complemento }}</p>
          </div>
          <div class="form-item">
            <label for="matricula">Matrícula do Imóvel</label>
            <p id="matricula">{{ form.matricula }}</p>
          </div>
          <div class="form-item">
            <label for="empreendimento">Empreendimento</label>
            <p id="empreendimento">{{ form.empreendimento }}</p>
          </div>
          <div class="form-item">
            <label for="uso">Uso</label>
            <p id="uso">{{ form.uso }}</p>
          </div>
          <div class="form-item">
            <label for="tipologia">Tipologia</label>
            <p id="tipologia">{{ form.tipologia }}</p>
          </div>
          <div class="form-item">
            <label for="condominio">Condomínio</label>
            <p id="condominio">{{ form.condominio }}</p>
          </div>
        </div>

        <div class="form-group details-group">
          <div class="form-item">
            <label for="andar_pavimento">Andar/Pavimento do Imóvel</label>
            <p id="andar_pavimento">{{ form.andar_pavimento }}</p>
          </div>
          <div class="form-item">
            <label for="idade_imovel">Idade do Imóvel</label>
            <p id="idade_imovel">{{ form.idade_imovel }}</p>
          </div>
          <div class="form-item">
            <label for="estado_conservacao">Conservação</label>
            <p id="estado_conservacao">{{ form.estado_conservacao }}</p>
          </div>
          <div class="form-item">
            <label for="total_dormitorios">Dormitórios</label>
            <p id="total_dormitorios">{{ form.total_dormitorios }}</p>
          </div>
          <div class="form-item">
            <label for="total_bwc">Total BWC</label>
            <p id="total_bwc">{{ form.total_bwc }}</p>
          </div>
          <div class="form-item">
            <label for="vagas_garagem">Vagas Garagem</label>
            <p id="vagas_garagem">{{ form.vagas_garagem }}</p>
          </div>
          <div class="form-item">
            <label for="valor_avaliacao">Valor declarado</label>
            <p id="valor_avaliacao">
              {{ formatCurrency(form.valor_avaliacao) }}
            </p>
          </div>
          <div class="form-item">
            <label for="area_m2">Área m²</label>
            <p id="area_m2">{{ form.area_m2 }}</p>
          </div>
          <div class="form-item">
            <label for="valor_m2">Valor do m²</label>
            <p id="valor_m2">{{ formatCurrency(form.valor_m2) }}</p>
          </div>
        </div>
      </form>

      <h1>Imóveis amostrais vinculados</h1>
      <div v-if="loading" class="loading-indicator">
        <i class="pi pi-spinner pi-spin"></i> Carregando...
      </div>
      <div class="styled-tables" v-if="selectedItems.length">
        <div class="title">
          <h5 style="margin: 10px">Itens Selecionados</h5>
        </div>
        <table style="margin: 0 auto; width: 100%; text-align: center">
          <thead id="selectedItensHeader">
            <tr>
              <th class="itemHeader">Endereço</th>
              <th class="itemHeader">Área (m²)</th>
              <th class="itemHeader">Quartos</th>
              <th class="itemHeader">Banheiros</th>
              <th class="itemHeader">Vagas</th>
              <th class="itemHeader">Preço (R$)</th>
              <th class="itemHeader">Preço por (m²)</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in selectedItems"
              :key="item.index"
              :class="{
                'max-value': item.valor / item.area_m2 === maxValorPerM2,
                'min-value': item.valor / item.area_m2 === minValorPerM2,
                
              }"
            >
              <td class="itemData">{{ item.endereco }}</td>
              <td class="itemData">{{ item.area_m2 || "" }}m²</td>
              <td class="itemData">{{ item.total_dormitorios }}</td>
              <td class="itemData">{{ item.total_bwc }}</td>
              <td class="itemData">{{ item.vagas_garagem }}</td>
              <td>
                R$
                {{
                  item.valor.toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </td>
              <td>
                R$
                {{
                  item.area_m2 && item.valor
                    ? (item.valor / item.area_m2).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""
                }}
                <!-- Imagem de seta para o valor máximo -->
                <img
                  v-if="item.valor / item.area_m2 === maxValorPerM2"
                  src="/max.png"
                  alt="Max Value"
                  style="width: 16px; height: 16px; margin-left: 5px"
                />
                <!-- Imagem de seta para o valor mínimo -->
                <img
                  v-if="item.valor / item.area_m2 === minValorPerM2"
                  src="/min.png"
                  alt="Min Value"
                  style="width: 16px; height: 16px; margin-left: 5px"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          margin: 20px auto;
          width: 100%;
        "
      >
        <!-- Caixa para Média das Amostras Selecionadas -->
        <div
          style="
            width: 48%;
            height: 35px;
            border: 1px solid #0fb5ac;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f4f4f4;
          "
        >
          <p
            style="
              margin: 0;
              font-family: 'Poppins', sans-serif;
              font-weight: 700;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.15px;
              color: #136e66;
            "
          >
            Média das amostras selecionadas:
            <span style="color: #000000">
              R$
              {{
                averageValuePerM2.toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}
              por m²
            </span>
          </p>
        </div>

        <!-- Caixa para Mediana das Amostras Selecionadas -->
        <div
          style="
            width: 48%;
            height: 35px;
            border: 1px solid #0fb5ac;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f4f4f4;
          "
        >
          <p
            style="
              margin: 0;
              font-family: 'Poppins', sans-serif;
              font-weight: 700;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.15px;
              color: #136e66;
            "
          >
            Mediana das amostras selecionadas:
            <span style="color: #000000">
              R$
              {{
                medianValuePerM2.toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}
              por m²
            </span>
          </p>
        </div>
      </div>

      <hr class="linha" />

      <h1>Adicionar o Valor arbitrado para o imóvel</h1>
      <div v-if="loading" class="loading-indicator">
        <i class="pi pi-spinner pi-spin"></i> Carregando...
      </div>
      <form @submit.prevent="updateValue">
        <br />
        <div
          class="textarea-grid"
          style="
            display: flex;
            gap: 10px; /* Diminuí o gap para aproximar os campos */
            align-items: flex-start;
            margin-top: 20px;
          "
        >
          <!-- Campo de Observações -->
          <div class="info-textarea" style="flex: 2">
            <label
              class="span"
              for="fundamentacao"
              style="
                display: block;
                margin-bottom: 5px;
                font-family: 'Poppins', sans-serif;
              "
            >
              Fundamento Legal e Observações Adicionais
            </label>
            <textarea
              id="fundamentacao"
              v-model="form.fundamentacao"
              maxlength="500"
              style="
                width: 100%;
                height: 160px;
                padding: 12px;
                border: 1px solid #ccc;
                border-radius: 5px;
                resize: none;
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
              "
            ></textarea>
          </div>

          <!-- Campo de Input -->
          <div class="info-textarea" style="flex: 1.6">
            <label
              class="span"
              for="valor-declarado"
              style="
                display: block;
                margin-bottom: 5px;
                font-family: 'Poppins', sans-serif;
                gap: 20px;
              "
            >
              Valor arbitrado para o imóvel:
            </label>
            <input
              class="input"
              v-money="moneyOptions"
              v-model="valorDeclarado"
              id="valor-declarado"
              required
              style="
                width: 93%;
                height: 20px; /* Aumentando a altura */
                padding: 10px; /* Aumentando o padding para tornar o input mais confortável */
                border: 1px solid #ccc;
                border-radius: 5px;
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
              "
            />
            <br /><br /><br /><br />
            <span
              style="
                color: #64748b;
                font-family: 'Poppins', sans-serif;
                font-size: 12px;
                line-height: 1.5;
                gap: 20px;
              "
            >
              As conclusões e recomendações contidas nesta avaliação imobiliária
              devem ser consideradas como um auxílio à tomada de decisão. O
              servidor deve realizar a sua própria análise conforme contexto
              específico do imóvel avaliado.
            </span>
          </div>

          </div>

          <br /><br />
          <div style="display: flex; align-items: center">
            <input
              type="checkbox"
              id="exemploCheckbox"
              v-model="isChecked"
              style="margin-right: 10px"
              @change="toggleTexto"
            />
            <label
              for="exemploCheckbox"
              style="font-family: 'Poppins', sans-serif; font-size: 14px"
            >
              Usar Texto padrão
            </label>
          </div>
        <div class="button-container" style="margin-top: 0px !important">
          <button type="button" class="cancel-buttons" @click="closeModal">
            Cancelar
          </button>
          <button type="submit" class="submit-buttons" :disabled="loading">
            Salvar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import axios from "axios";

export default {
  props: {
    reportId: Number,
  },

  data() {
    return {
      showModal: false,
      valorDeclarado: "",
      originalValorDeclarado: "",
      loading: false,
      isChecked: false,
      textoPadrao: "",
      isSaving: false,
      moneyOptions: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        masked: false,
      },
      successMessage: "",
      errorMessage: "",
      form: {
        fundamentacao: "",
      },
      laudo: {
        valorAvaliacao: "",
        valorM2: "",
        area_m2: "",
        itensSelecionados: [],
      },
      originalForm: {
        fundamentacao: "",
      },
      selectedItems: [],
      saved: false,
      laudoID: null,
    };
  },
  watch: {
    reportId(newVal) {
      if (newVal) {
        this.loadReportData(newVal);
        this.loadReportDatas(newVal);
        this.isChecked = false;
        this.showModal = true;
      }
    },
  },
  computed: {
    formattedValorDeclarado() {
      return this.formatCurrency(this.valorDeclarado);
    },
    formattedValorAvaliacao() {
      return this.formatCurrency(this.form.valor_avaliacao);
    },
    formattedValorM2() {
      return this.form.valor_m2 ? this.formatCurrency(this.form.valor_m2) : "";
    },
    maxValorPerM2() {
      return Math.max(
        ...this.selectedItems.map((item) => item.valor / item.area_m2 || 0)
      );
    },
    // Calcula o preço mínimo por m²
    minValorPerM2() {
      return Math.min(
        ...this.selectedItems.map(
          (item) => item.valor / item.area_m2 || Infinity
        )
      );
    },
    averageValuePerM2() {
      const values = this.selectedItems
        .filter((item) => item.area_m2 && item.valor)
        .map((item) => item.valor / item.area_m2);

      if (values.length === 0) return 0;

      const total = values.reduce((sum, value) => sum + value, 0);
      return total / values.length;
    },
    medianValuePerM2() {
      const values = this.selectedItems
        .filter((item) => item.area_m2 && item.valor)
        .map((item) => item.valor / item.area_m2)
        .sort((a, b) => a - b);

      if (values.length === 0) return 0;

      const mid = Math.floor(values.length / 2);
      return values.length % 2 !== 0
        ? values[mid]
        : (values[mid - 1] + values[mid]) / 2;
    },
  },
  methods: {
    formatCurrency(value) {
      if (typeof value === "undefined") return "R$ 0,00";

      value = value.replace(/[^\d,]/g, "");

      const numericValue = parseFloat(value.replace(",", ".")) || 0;

      if (isNaN(numericValue)) {
        return "R$ 0,00";
      }

      let number = numericValue.toFixed(2).replace(".", ",");
      let [integerPart, decimalPart] = number.split(",");

      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      return `R$ ${integerPart},${decimalPart}`;
    },
    async toggleTexto() {
      if (this.isChecked) {
        this.isSaving = true; // Indica que a requisição está em andamento
        try {
          const fiscalId = sessionStorage.getItem("fiscal_id"); // Recupere o ID do fiscal da sessão
          if (!fiscalId) {
            // Exibe a mensagem de erro com SweetAlert
            await Swal.fire({
              icon: "error",
              title: "Erro!",
              text: "Fiscal ID não encontrado.",
              confirmButtonText: "Ok",
              confirmButtonColor: "#FF9364", // Cor do botão
            });
            return;
          }

          // Faz a requisição para buscar o texto padrão da API
          const response = await axios.get(
            `${process.env.VUE_APP_API_BASE_URL}/api/preferencia/usuario/${fiscalId}`,
            {
              headers: {
                "x-access-token": process.env.VUE_APP_API_TOKEN,
              },
            }
          );

          // Atribui o texto retornado pela API diretamente no campo de fundamentação
          this.form.fundamentacao = response.data.texto_padrao || "";
        } catch (error) {
          console.error("Erro ao buscar o texto padrão:", error);
          // Exibe a mensagem de erro com SweetAlert
          await Swal.fire({
            icon: "error",
            title: "Erro!",
            text: "Erro ao buscar o texto padrão.",
            confirmButtonText: "Ok",
            confirmButtonColor: "#FF9364", // Cor do botão
          });
          this.form.fundamentacao = ""; // Limpa o campo em caso de erro
        } finally {
          this.isSaving = false; // Finaliza o carregamento
        }
      } else {
        this.form.fundamentacao = ""; // Limpa o texto manual se o checkbox for desmarcado
      }
    },

    handleInput(event) {
      const inputValue = event.target.value;

      const rawValue = inputValue.replace(/[^\d,]/g, "");

      this.valorDeclarado = rawValue;

      const normalizedValue = rawValue.replace(",", ".");

      this.$nextTick(() => {
        event.target.value = this.formatCurrency(normalizedValue);
      });
    },

    calcularPrecoPorM2(area, price) {
      const areaNum = parseFloat(area);
      const priceNum = parseFloat(price);

      if (!isNaN(areaNum) && !isNaN(priceNum) && areaNum > 0) {
        return ` ${(priceNum / areaNum).toFixed(2).replace(".", ",")}`;
      }
      return "R$ ";
    },
    async loadReportData(reportId) {
      this.loading = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/api/laudo/${reportId}`,
          {
            headers: {
              "x-access-token": process.env.VUE_APP_API_TOKEN,
            },
          }
        );
        if (response.status !== 200) {
          throw new Error("Erro ao buscar os dados do relatório");
        }

        const laudoData = response.data;

        this.form = {
          endereco: laudoData.endereco || "",
          nome_comprador: laudoData.nome_comprador || "",
          cpf_comprador: laudoData.cpf_comprador || "",
          itensSelecionados: laudoData.itens_selecionados || [],
          complemento: laudoData.complemento || "",
          matricula: laudoData.matricula || "",
          empreendimento: laudoData.empreendimento || "",
          uso: laudoData.uso || "",
          tipologia: laudoData.tipologia || "",
          condominio: laudoData.condominio || "",
          andar_pavimento: laudoData.andar_pavimento || "",
          idade_imovel: laudoData.idade_imovel || "",
          estado_conservacao: laudoData.estado_conservacao || "",
          total_dormitorios: laudoData.total_dormitorios || "",
          total_bwc: laudoData.total_bwc || "",
          vagas_garagem: laudoData.vagas_garagem || "",
          valor_avaliacao: laudoData.valor_avaliacao || "",
          area_m2: laudoData.area_m2 || "",
          valor_m2: laudoData.valor_m2 || "",
          fundamentacao: laudoData.fundamentacao || "",
          fundamento_legal: laudoData.fundamento_legal || "",
          fiscal_id: laudoData.fiscal_id || null,
          texto_padrao: laudoData.texto_padrao || "",
        };

        this.originalForm = { ...this.form };

        this.valorDeclarado = laudoData.valor_arbitrado || "";
        this.originalValorDeclarado = laudoData.valor_arbitrado || "";
        this.showModal = true;
      } catch (error) {
        console.error("Erro ao carregar os dados do relatório:", error);
        this.errorMessage = "Erro ao carregar os dados do relatório";
      } finally {
        this.loading = false;
      }
    },
    async loadReportDatas(reportId) {
      this.loading = true;
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/api/imoveis/laudo/${reportId}`,
          {
            headers: {
              "x-access-token": process.env.VUE_APP_API_TOKEN,
            },
          }
        );

        // Log the response status and URL for debugging
        console.log("Response Status:", response.status);
        console.log(
          "Requested URL:",
          `${process.env.VUE_APP_API_BASE_URL}/api/imoveis/laudo/${reportId}`
        );

        if (!response.ok) {
          throw new Error("Erro ao buscar os dados do imóvel");
        }

        const data = await response.json();

        // Log the fetched data for debugging
        console.log("Fetched Data:", data);

        this.selectedItems = data; // Atualiza a lista de itens selecionados
      } catch (error) {
        console.error("Erro ao carregar os dados do imóvel:", error);
        this.errorMessage = "Erro ao carregar os dados do imóvel";
      } finally {
        this.loading = false;
      }
    },

    closeModal() {
      this.showModal = false;
      this.form = { ...this.originalForm }; // Reset to original values
      this.valorDeclarado = this.originalValorDeclarado;
      this.errorMessage = "";
    },
    formatValue(value) {
      return value.replace(/\./g, "").replace(",", ".");
    },
    async updateValue() {
      this.loading = true;
      try {
        const changes = {};
        if (this.valorDeclarado !== this.originalValorDeclarado) {
          const numericValue = this.valorDeclarado
            .replace("R$", "")
            .replace(/\./g, "")
            .replace(",", ".")
            .trim();

          changes.valor_arbitrado = parseFloat(numericValue);
        }
        if (this.form.fundamentacao !== this.originalForm.fundamentacao) {
          changes.fundamentacao = this.form.fundamentacao;
        }

        if (this.form.fundamento_legal !== this.originalForm.fundamento_legal) {
          changes.fundamento_legal = this.form.fundamento_legal;
        }

        if (Object.keys(changes).length > 0) {
          const response = await axios.put(
            `${process.env.VUE_APP_API_BASE_URL}/api/laudo/${this.reportId}`,
            changes,
            {
              headers: {
                "x-access-token": process.env.VUE_APP_API_TOKEN,
              },
            }
          );
          if (response.status === 200) {
            this.successMessage = "Valor salvo com sucesso!";
            this.closeModal(); // Optionally close the modal
          }
          if (response.status !== 200) {
            throw new Error("Erro ao salvar o valor arbitrado");
          }
          this.successMessage = "Dados salvos com sucesso";
          Swal.fire({
            icon: "success",
            title: "Sucesso",
            text: this.successMessage,
          });
          this.$emit("valorAtualizado");
          this.closeModal();
        } else {
          Swal.fire({
            icon: "info",
            title: "Sem alterações",
            text: "Nenhuma alteração detectada para salvar.",
          });
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.errorMessage = error.response.data.message;
        } else {
          this.errorMessage = "Erro ao salvar os dados";
        }
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: this.errorMessage,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
