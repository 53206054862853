<template>
  <div class="modal-overlay secondary">
    <div class="modal-content">
      <h1>Adicionar Anúncio Manual</h1>
      <form @submit.prevent="submitForm(reportId)">
        <!-- Primeira Linha - Endereço, Bairro e Área -->
        <div class="form-group address-group">
          <div class="form-group-item">
            <label for="endereco">Endereço (obrigatório)</label>
            <input
              v-model="newAd.endereco"
              type="text"
              id="endereco"
              maxlength="100"
              placeholder="Digite"
              required
            />
          </div>
          <div class="form-group-item">
            <label for="bairro">Bairro (obrigatório)</label>
            <input
              v-model="newAd.bairro"
              type="text"
              id="bairro"
              placeholder="Digite"
              maxlength="20"
              required
            />
          </div>
          <div class="form-group-item">
            <label for="area">Área m² (obrigatório)</label>
            <input
              v-model="newAd.area_m2"
              type="text"
              id="area"
              placeholder="Digite"
              maxlength="20"
              required
              @input="validateNumber('area_m2')"
            />
          </div>
        </div>

        <!-- Segunda Linha - Quartos, Banheiros, Vagas, Preço -->
        <div class="form-group details-group">
          <div class="form-group-item">
            <label for="quartos">Quartos</label>
            <input
              v-model="newAd.total_dormitorios"
              type="number"
              id="quartos"
              placeholder="Digite"
              @input="validateNumber('total_dormitorios')"
            />
          </div>
          <div class="form-group-item">
            <label for="banheiros">Banheiros</label>
            <input
              v-model="newAd.banheiros"
              type="number"
              id="banheiros"
              placeholder="Digite"
              @input="validateNumber('banheiros')"
            />
          </div>
          <div class="form-group-item">
            <label for="vagas">Vagas</label>
            <input
              v-model="newAd.vagas"
              type="number"
              id="vagas"
              placeholder="Digite"
              @input="validateNumber('vagas')"
            />
          </div>
          <div class="form-group-item">
            <label for="preco">Preço (obrigatório)</label>
            <input
              type="text"
              v-money="moneyOptions"
              v-model="newAd.valor"
              id="valor"
              placeholder="Digite o preço"
              @blur="validarPreco"
            />
            <span v-if="precoErro" class="error-text">O preço é obrigatório.</span>
          </div>
        </div>

        <!-- Terceira Linha - Link ou Fonte -->
        <div class="form-group-item">
          <label for="linkFonte">Link ou Fonte (obrigatório)</label>
          <input
            v-model="newAd.link_anuncio"
            type="text"
            id="linkFonte"
            placeholder="Digite"
            class="link-input"
            required
          />
        </div>

        <div class="button-container">
          <button
            type="button"
            class="cancel-button"
            @click="cancelAction"
            :disabled="loading"
          >
            Cancelar
          </button>
          <button type="submit" class="submit-button" :disabled="loading || precoErro">
            Cadastrar Imóvel
          </button>
        </div>
        <p class="info-paragraph">
          A inserção de dados de forma manual é responsabilidade do usuário do
          sistema. Informações inseridas incorretamente podem distorcer o método
          de avaliação comparativa de imóveis e invalidar o procedimento.
        </p>
      </form>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";

export default {
  props: {
    showAddModal: Boolean,
    loading: Boolean,
    reportId: Number,
  },
  data() {
    return {
      newAd: {
        endereco: "",
        bairro: "",
        area_m2: "",
        total_dormitorios: "",
        total_bwc: "",
        vagas: "",
        valor: "",
        link_anuncio: "",
        adicao_manual: true, // O valor está fixo
        laudoID: null,
      },
      precoErro: false,
      moneyOptions: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        masked: false
      },
      adsList: [],
    };
  },
  created() {
    const fiscalId = sessionStorage.getItem("fiscal_id");
    this.laudoID = fiscalId ? parseInt(fiscalId) : null; 
    console.log("Fiscal ID recuperado:", this.laudoID); 
  },
  methods: {
    validateNumber(field) {
      this.newAd[field] = String(this.newAd[field]).replace(/[^\d]/g, ""); 
    },
  validarPreco() {
    const valorLimpio = this.cleanValue(this.newAd.valor);
    this.precoErro = !valorLimpio || isNaN(valorLimpio) || parseFloat(valorLimpio) <= 0;
  },

    cleanValue(value) {
      return value
        .replace(/R\$\s?/g, "")
        .replace(/\./g, "")
        .replace(",", ".");
    },

    async submitForm() {
     this.validarPreco();
    if (this.precoErro) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "O campo Preço é obrigatório!",
      });
      return;
    }
      const valor = this.cleanValue(this.newAd.valor);
      const area_m2 = parseFloat(this.newAd.area_m2);
      if (isNaN(area_m2)) {
        console.error("Área inválida");
        return;
      }

      const valor_m2 = parseFloat(valor) / area_m2;

      const formattedData = [
        {
          area_m2: this.newAd.area_m2,
          endereco: this.newAd.endereco,
          total_bwc: this.newAd.banheiros,
          laudo_id: this.reportId || "defaultValue",
          link_anuncio: this.newAd.link_anuncio,
          total_dormitorios: this.newAd.total_dormitorios,
          vagas_garagem: this.newAd.vagas,
          valor: valor,
          valor_m2: valor_m2.toString(),
          adicao_manual: true,
        },
      ];

      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/api/imoveis`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": process.env.VUE_APP_API_TOKEN,
            },
            body: JSON.stringify(formattedData), 
          }
        );

        if (response.ok) {
          const data = await response.json();
          console.log("Anúncio cadastrado com sucesso", data);

          // Exibe o alerta SweetAlert
          Swal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "Imóvel adicionado manualmente com sucesso!",
            confirmButtonText: "OK",
          });
          this.$emit("imovelAdicionado");
          this.$emit("close");
        } else {
          const errorData = await response.json();
          console.error("Erro ao cadastrar o anúncio", errorData);
        }
      } catch (error) {
        console.error("Erro de rede ou outro erro:", error);
      }
    },
    cancelAction() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
/* Estilos do modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.modal-overlay.secondary {
  z-index: 10000;  
}
.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #f4f4f4;
  padding: 20px;
  width: 1367px;
  height: 559px;
  border-radius: 12px;
}

h1 {
  font-size: 28px;
  color: #136e66;
  margin-bottom: 20px;
  text-align: left;
}

form {
  display: grid;
  gap: 20px;
}

.form-group-item {
  display: flex;
  flex-direction: column;
  padding: 15px;
}
.info-paragraph {
  color: #c82021;
  font-size: 12px;
  margin-top: -5px; 
  grid-column: 1 / -1; 
}
.button-container {
  margin-top: -10px;
}
input[type="text"],
input[type="number"],
select,
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
}

label {
  color: #09090b;
}

.address-group {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr; 
  gap: 10px;
}
.endereco-input {
  width: 100%; 
}

.bairro-input,
.area-input {
  width: 100%; 
}
.details-group {
  display: grid;
  grid-template-columns: repeat(
    4,
    1fr
  ); 
  gap: 10px;
}

.details-group .form-group-item {
  margin: 0;
}

.details-group label {
  font-size: 14px;
}

form .form-group-item input {
  font-size: 14px;
}

form .form-group-item input:focus {
  border-color: #136e66;
}


.link-input {
  width: 78% !important; 
}
.submit-button,
.cancel-button {
  width: 231px;
  height: 40px;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
}

.submit-button {
  color: #fff;
  background-color: #136e66;
}

.submit-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
  opacity: 0.8;
}
</style>
